import request from '@/utils/request'

// 我的资产首页-总资产
export function sum(data) {

  return request({
    url: '/api/sale/user/assets/sum',
    method: 'post',
    data
  })
}

// 我的资产首页-环形图表占比
export function holding(data) {

    return request({
      url: '/api/sale/user/assets/holding',
      method: 'post',
      data
    })
  }

  // 我的资产首页-柱状图
export function charData(data) {

    return request({
      url: '/api/sale/user/assets/charData',
      method: 'post',
      data
    })
  }
