<template>
  <section class="chart" ref="chart"></section>
</template>

<script>
  export default {
    name: 'eChart',
    props: {
      width: {
        type: [Number, String]
      },
      height: {
        type: [Number, String]
      },
      option: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        chart: null
      }
    },
    watch: {
      option:{
        deep:true,
        handler(n,o){
          this.setOption()
        }
      }

    },
    mounted() {
      let chartBox = this.$refs.chart
      let opts = {}
      if(this.width) {
        opts.width= this.width
      }
      if(this.height) {
        opts.height= this.height
      }
      this.chart = this.$echarts.init(chartBox, null, opts)
      this.setOption()
    },
    methods: {
      setOption() {
        this.chart.setOption(this.option);
      }
    }
  }
</script>
