<template>
  <div class="bg">
    <section class="publicfund">
      <div class="publicfund_cont">
        <div class="title_">我的资产</div>
        <div class="income">
          <div class="income-box">
            <div class="income-text">
              <p>{{ fundData.todayIncome || '--' }}</p>
              <div>昨日收益(元)</div>
            </div>
            <img src="@/static/img/myFund/today.png" alt="" />
          </div>
          <div class="income-box">
            <div class="income-text">
              <p>{{ fundData.holdingIncome || '--' }}</p>
              <div>持有收益(元)</div>
            </div>
            <img src="@/static/img/myFund/holding.png" alt="" />
          </div>
          <div class="income-box">
            <div class="income-text">
              <p>{{ fundData.accumIncome || '--' }}</p>
              <div>累计收益(元)</div>
            </div>
            <img src="@/static/img/myFund/total.png" alt="" />
          </div>
        </div>
        <div class="mychart">
          <div class="mychart-t">
            <div class="total-fund">总资产</div>
            <div class="total">
              <span class="total-num">{{ fundData.assetsTotal || '--' }}</span>
              <span class="danwei">元</span>
            </div>
          </div>
          <div id="circle" class="mycircle"><e-chart :width="260" :height="260" :option="totalOption" /></div>
          <div class="inner">
            <span class="inner-num">{{ fundData.assetsTotal || '--' }}</span>
            <span class="inner-text">总资产</span>
          </div>
          <div class="legent">
            <div class="legent-box">
              <div class="one"></div>
              <div class="text" @click="kind(1)">
                <div class="t">惠金宝</div>
                <div class="m-1">{{ assetsShare.xjb }}%</div>
                <div class="b">{{ fundData.xjbAssetsTotal }}</div>
              </div>
            </div>
            <div class="legent-box">
              <div class="two"></div>
              <div class="text" @click="kind(2)">
                <div class="t">公募基金</div>
                <div class="m-2">{{ assetsShare.pub }}%</div>
                <div class="b">{{ fundData.pubAssetsTotal }}</div>
              </div>
            </div>
            <div class="legent-box">
              <div class="three"></div>
              <div class="text" @click="kind(3)">
                <div class="t">高端理财</div>
                <div class="m-3">{{ assetsShare.pri }}%</div>
                <div class="b">{{ fundData.priAssetsTotal }}</div>
              </div>
            </div>
          </div>
          <div class="mychart-r">
            <div class="linechart-t">
              <div v-if="kinds == 1">{{ fundData.xjbTodayIncome || '--' }}</div>
              <div v-else-if="kinds == 2">{{ fundData.pubTodayIncome || '--' }}</div>
              <div v-else>{{ fundData.priTodayIncome || '--' }}</div>
              <span v-if="kinds == 1">惠金宝最新收益</span>
              <span v-else-if="kinds == 2">公募基金最新收益</span>
              <span v-else>高端理财最新收益</span>
            </div>
            <div v-if="kinds == 1" class="one"><el-progress :text-inside="true" :stroke-width="13" :percentage="xjbIncomeShare"></el-progress></div>
            <div v-else-if="kinds == 2" class="two"><el-progress :text-inside="true" :stroke-width="13" :percentage="pubIncomeShare"></el-progress></div>
            <div v-else class="three"><el-progress :text-inside="true" :stroke-width="13" :percentage="priIncomeShare"></el-progress></div>
            <div class="linechart-b">
              <div class="line-box">
                <div :class="kinds == 1 ? 'line-l-1' : kinds == 2 ? 'line-l-2' : 'line-l-3'"></div>
                <div class="linetext">
                  <p v-if="kinds == 1">惠金宝收益</p>
                  <p v-else-if="kinds == 2">公募基金收益</p>
                  <p v-else>高端理财收益</p>
                  <span v-if="kinds == 1">{{ fundData.xjbAccumIncome || '--' }}￥</span>
                  <span v-else-if="kinds == 2">{{ fundData.pubAccumIncome || '--' }}￥</span>
                  <span v-else>{{ fundData.priAccumIncome || '--' }}￥</span>
                </div>
              </div>
              <div class="line-box">
                <div class="line-r"></div>
                <div class="linetext">
                  <p>总收益</p>
                  <span>{{ fundData.accumIncome || '--' }}￥</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mydeal">
          <div class="mydeal-t">
            <div class="mydeal-t-l">快捷交易</div>
            <router-link to="/myFavors/trade-record" class="mydeal-t-r">交易记录</router-link>
          </div>
          <div class="content">
            <div class="box" :class="iconnum == 1 ? 'checked-box' : ''">
              <img src="@/static/img/myFund/买入.png" alt="" />
              <router-link class="box-text" to="/fund-market/index">买入</router-link>

              <router-link to="/fund-market/index" @click="myico(1)" :class="iconnum == 1 ? 'checked-icon' : ''" class="iconfont ico">&#xe6a7;</router-link>
            </div>
            <div class="box" :class="iconnum == 2 ? 'checked-box' : ''">
              <img src="@/static/img/myFund/卖出.png" alt="" />
              <router-link class="box-text" :to="{ name: 'PublicFund' }">卖出</router-link>

              <router-link :to="{ name: 'PublicFund' }" @click="myico(2)" :class="iconnum == 2 ? 'checked-icon' : ''" class="iconfont ico">&#xe6a7;</router-link>
            </div>
            <div class="box" :class="iconnum == 3 ? 'checked-box' : ''">
              <img src="@/static/img/myFund/转换.png" alt="" />
              <router-link class="box-text" to="/myFavors/my-asset/publicfund">转换</router-link>
              <router-link to="/myFavors/my-asset/publicfund" @click="myico(3)" :class="iconnum == 3 ? 'checked-icon' : ''" class="iconfont ico">&#xe6a7;</router-link>
            </div>
            <div class="box" :class="iconnum == 4 ? 'checked-box' : ''">
              <img src="@/static/img/myFund/定投.png" alt="" />
              <router-link class="box-text" :to="{ name: 'Fixedarea' }">定投</router-link>
              <router-link :to="{ name: 'Fixedarea' }" @click="myico(4)" :class="iconnum == 4 ? 'checked-icon' : ''" class="iconfont ico">&#xe6a7;</router-link>
            </div>
          </div>
        </div>
        <div class="myhave">
          <div class="title">我的持仓</div>
          <div class="myhave-content">
            <div class="box" @click="myhaveclick(1)" :class="myhaveclicknum == 1 ? 'haveclick' : ''">
              <div class="box-t">
                <div class="text">惠金宝</div>
                <div class="box-r " :class="fundData.xjbTodayIncome < 0 ? 'decline' : 'rise'">
                  <span class="num">{{ fundData.xjbTodayIncome || 0 }}</span>
                  <span v-if="fundData.xjbTodayIncome >= 0" class="iconfont num">&#xe6a8;</span>
                  <span v-else class="iconfont num">&#xe6a6;</span>
                </div>
              </div>
              <div class="box-m">精选货基 零钱收益</div>
              <div class="box-b">{{ fundData.xjbAssetsTotal || 0 }}元</div>
            </div>
            <div class="box" @click="myhaveclick(2)" :class="myhaveclicknum == 2 ? 'haveclick' : ''">
              <div class="box-t">
                <div class="text">公募基金</div>
                <div class="box-r" :class="fundData.pubTodayIncome < 0 ? 'decline' : 'rise'">
                  <span class="num">{{ fundData.pubTodayIncome || 0 }}</span>
                  <span v-if="fundData.pubTodayIncome >= 0" class="iconfont num">&#xe6a8;</span>
                  <span v-else class="iconfont num">&#xe6a6;</span>
                </div>
              </div>
              <div class="box-m">优选纯债 货币进阶</div>
              <div class="box-b">{{ fundData.pubAssetsTotal || 0 }}元</div>
            </div>
            <div class="box" @click="myhaveclick(3)" :class="myhaveclicknum == 3 ? 'haveclick' : ''">
              <div class="box-t">
                <div class="text">高端理财</div>
                <div class="box-r" :class="fundData.priTodayIncome < 0 ? 'decline' : 'rise'">
                  <span class="num">{{ fundData.priTodayIncome || 0 }}</span>
                  <span v-if="fundData.priTodayIncome >= 0" class="iconfont num">&#xe6a8;</span>
                  <span v-else class="iconfont num">&#xe6a6;</span>
                </div>
              </div>
              <div class="box-m">好债打底 稳中求进</div>
              <div class="box-b">{{ fundData.priAssetsTotal || 0 }}元</div>
            </div>
          </div>
        </div>
        <div class="myincome">
          <div class="myincome-title">我的收益</div>
          <div class="chartbox">
            <div class="myincom-l">
              <div class="myincome-chart" v-show="barData.length > 0">
                <e-chart :width="850" :height="260" :option="incomeOption" />
              </div>
              <div v-show="barData.length == 0" class="null_tab">
                <img src="@/static/img/my/null_bg.png" alt="" />
                <div class="null_txt">暂无相关数据</div>
              </div>
              <div class="bbtn">
                <div class="bbtn-box" @click="mybg(1)" :class="bgnum == 1 ? 'bg-' : ''">日收益</div>
                <div class="bbtn-box" @click="mybg(2)" :class="bgnum == 2 ? 'bg-' : ''">月收益</div>
                <div class="bbtn-box" @click="mybg(3)" :class="bgnum == 3 ? 'bg-' : ''">年收益</div>
                <div class="bbtn-box" @click="mybg(4)" :class="bgnum == 4 ? 'bg-' : ''">累计收益</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { sum, holding, charData } from '@/api/myFundIndex';
import eChart from '@/components/eChart.vue';

import * as echarts from 'echarts';
export default {
  name: 'MyFundIndex',
  components: {
    eChart
  },
  data() {
    return {
      kinds: 1,
      assetsShare: {
        xjb: 0,
        pub: 0,
        pri: 0
      },
      accumIncomeShare: 0, //总收益率
      totalFund: 1,
      persent: 1,
      bgnum: 1,
      barData: [],
      iconnum: 1,
      myhaveclicknum: 1,
      userInfo: {},
      xjbIncomeShare: 0, //惠金宝收益率
      pubIncomeShare: 0, //公募基金收益率
      priIncomeShare: 0, //私募基金收益率
      todayIncomeShare: 0, //昨日收益率
      fundData: {
        holdingIncome: 0,
        xjbAccumIncome: 0,
        pubAccumIncome: 0,
        priAccumIncome: 0,
        accumIncome: 0,
        todayIncome: 0,
        xjbTodayIncome: 0,
        pubTodayIncome: 0,
        priTodayIncome: 0,
        assetsTotal: 0,
        xjbAssetsTotal: 0,
        priAssetsTotal: 0,
        pubAssetsTotal: 0
      },
      fundShare: {
        xjbHolding: 0,
        pubHolding: 0,
        priHolding: 0,
        xjbShare: 0,
        priShare: 0,
        pubShare: 0
      },
      totalOption: {},
      incomeOption: {}
    };
  },

  created() {
    this.fundsum();
    this.myshare();
    this.mycharData();
  },
  methods: {
    kind(n) {
      this.kinds = n;
    },
    mycharData() {
      charData({ type: this.bgnum }).then(res => {
        this.barData = res.data || [];
        this.drawBar();
      });
    },
    myshare() {
      holding().then(res => {
        this.fundShare = res.data || {};
      });
    },
    fundsum() {
      sum().then(res => {
        this.fundData = res.data || {};
        this.drawTotal();
        this.fundData.assetsTotal = res.data.assetsTotal.toFixed(2);
        this.fundData.xjbAssetsTotal = res.data.xjbAssetsTotal.toFixed(2);
        this.fundData.pubAssetsTotal = res.data.pubAssetsTotal.toFixed(2);
        this.fundData.priAssetsTotal = res.data.priAssetsTotal.toFixed(2);
        if (this.fundData.accumIncome) {
          this.xjbIncomeShare = (this.fundData.xjbAccumIncome / this.fundData.accumIncome) * 100;
          this.pubIncomeShare = (this.fundData.pubAccumIncome / this.fundData.accumIncome) * 100;
          this.priIncomeShare = (this.fundData.priAccumIncome / this.fundData.accumIncome) * 100;
          this.todayIncomeShare = (this.fundData.todayIncome / this.fundData.accumIncome) * 100;
        }
        if (this.fundData.assetsTotal) {
          this.assetsShare.xjb = ((this.fundData.xjbAssetsTotal / this.fundData.assetsTotal) * 100).toFixed(2);
          this.assetsShare.pub = ((this.fundData.pubAssetsTotal / this.fundData.assetsTotal) * 100).toFixed(2);
          this.assetsShare.pri = ((this.fundData.priAssetsTotal / this.fundData.assetsTotal) * 100).toFixed(2);
        }
      });
    },
    mybg(n) {
      this.bgnum = n;
      this.mycharData();
    },
    myhaveclick(n) {
      this.myhaveclicknum = n;
    },
    myico(n) {
      this.iconnum = n;
    },
    /** 收益 */
    drawBar() {
      var xAxisData = [];
      var data = [];
      this.barData.forEach((element, i) => {
        let date = element.assetsDate;
        if (/^\d{8}$/.test(date)) {
          date = element.assetsDate.$formatDate();
        } else if (/^\d{6}$/.test(date)) {
          date = element.assetsDate.$formatDate('yyyy-MM');
        }
        xAxisData.push(date);
        data.push(element.income);
      });
      
      let incomeOption = {
        title: {
          text: '收益 / 时间',
          textStyle: {
            fontSize: 16,
            color: '#4F4F4F'
          },
          left: 10,
          top: 20
        },
        xAxis: {
          data: xAxisData,
          axisTick: {
            // 坐标轴刻度相关设置
            show: false //X轴默认是显示的
          },
          axisLine: {
            lineStyle: {
              color: '#EBEBEB'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#EBEBEB'
            }
          },
          splitArea: { show: false },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#979797',
              fontSize: 9
            },
            formatter: (
              v,
              i //this.barData.length <= 10 || this.barData.length-1 == i || i % 3 == 0 ? v : ''
            ) => {
              let count = this.barData.length;
              if (count <= 10 || i + 1 == count) {
                return v;
              }
              if (count <= 20) {
                if ((count % 2 == 1 && i % 2 == 0) || (count % 2 == 0 && i % 2 == 0 && i + 2 != count)) return v;
              } else {
                if ((count % 3 == 1 && i % 3 == 0) || (count % 3 != 1 && i % 3 == 0 && i + 2 != count)) return v;
              }
              return '';
            }
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#EBEBEB'
            }
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#979797',
              fontSize: 9
            }
          }
        },
        tooltip: {
          show: true, //---是否显示提示框,默认为true
          trigger: 'item', //---数据项图形触发
          axisPointer: {
            //---指示样式
            type: 'shadow',
            axis: 'auto'
          }
          //formatter: '{b0} <br> {c0}'
        },
        grid: {},
        series: [
          {
            name: '盈亏',
            type: 'line',
            stack: 'one',
            data: data,
            // emphasis: emphasisStyle,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [
                  {
                    offset: 0,
                    color: '#ffa59e'
                  },
                  {
                    offset: 0.9,
                    color: '#e45247'
                  }
                ]),
                barBorderRadius: [4, 4, 0, 0]
              }
            }
          }
        ]
      }      
      this.incomeOption = incomeOption
    },
    /** 总资产 */
    drawTotal() {
      let totalOption = {
        tooltip: {
          trigger: 'item',
          position: 'inside',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        // 控制圆环图的渐变颜色
        color: [
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#ffa59e'
            },
            {
              offset: 0.9,
              color: '#e45247'
            }
          ]),
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#F7D9B7'
            },
            {
              offset: 0.9,
              color: '#EABF96'
            }
          ]),
          new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#A4C3F5'
            },
            {
              offset: 0.9,
              color: '#E0ECFF'
            }
          ])
        ],
        series: [
          {
            name: '总资产',
            type: 'pie',
            radius: ['55%', '70%'],
            avoidLabelOverlap: false,
            clockWise: false,
            itemStyle: {
              borderRadius: 30,
              borderColor: '#fff',
              borderWidth: 5
            },
            label: {
              show: false,
              position: 'center',
              formatter: '{b|{b}：}{c}  ({d}%) '
            },
            data: [
              { value: this.fundData.xjbAssetsTotal, name: '惠金宝' },
              { value: this.fundData.pubAssetsTotal, name: '公募基金' },
              { value: this.fundData.priAssetsTotal, name: '高端理财' }
            ]
          }
        ]
      };
      this.totalOption = totalOption;
    }
  }
};
</script>
<style lang="less">
.el-progress {
  line-height: 0;
}
// .el-progress-bar__inner {
//   background: linear-gradient(269deg, #ffa59e 0%, #e45247 100%);
// }
.mychart-r {
  .one .el-progress-bar__inner {
    background: linear-gradient(269deg, #ffa59e 0%, #e45247 100%);
  }
  .two .el-progress-bar__inner {
    background: linear-gradient(273deg, #f7d9b7 0%, #eabf96 100%);
  }
  .three .el-progress-bar__inner {
    background: linear-gradient(0deg, #a4c3f5 0%, #e0ecff 100%);
  }
}
.proleft {
  .el-progress-bar__inner {
    background: linear-gradient(0deg, #50d977 0%, #afefc2 93%);
  }
}
.el-progress-bar__innerText {
  display: none;
}
</style>
<style lang="less" scoped>
.profitac {
  color: #e45247 !important;
}
.profit {
  color: #01b834 !important;
}
.publicfund {
  min-height: 600px;
  display: flex;
  .publicfund_cont {
    padding: 40px 40px 0px 40px;
    margin-bottom: 40px;
    background: #fff;
    min-height: 1800px;
    width: 930px;
    overflow: hidden;
    .title_ {
      width: 850px;
      height: 60px;
      border-bottom: 1px solid #edf1f7;
      font-size: 20px;
      color: #1f1f1f;
    }
    .income {
      height: 75px;
      width: 800px;
      margin: 41px 0;
      display: flex;
      .income-box {
        width: 240px;
        height: 75px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 24px;
        border-radius: 7px;
        box-shadow: 0px 2px 6px 0px #edf1f7;
        .income-text {
          p {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          div {
            font-size: 10px;
            font-weight: 300;
          }
        }
      }
    }
    .mychart {
      width: 850px;
      height: 469px;
      border-top: #edf1f7 1px solid;
      border-bottom: #edf1f7 1px solid;
      position: relative;
      .mychart-t {
        display: flex;
        justify-content: space-between;
        margin: 44px 0 50px 0;
        .total-fund {
          font-size: 18px;
          font-weight: 700;
        }
        .total {
          .total-num {
            font-size: 22px;
            font-weight: 700;
            margin-right: 10px;
          }
          .danwei {
            font-size: 16px;
            font-weight: 700;
            color: #bdc0cb;
          }
        }
      }
      .mycircle {
        width: 260px;
        height: 260px;
        position: absolute;
        left: -40px;
      }
      .inner {
        width: 180px;
        height: 60px;
        position: absolute;
        text-align: center;
        top: 235px;
        .inner-num {
          font-size: 26px;
          font-weight: 700;
          display: block;
          margin-bottom: 10px;
        }
        .inner-text {
          font-size: 12px;
          font-weight: 400;
          color: #bdc0cb;
        }
      }
      .legent {
        width: 129px;
        height: 210px;
        position: absolute;
        left: 200px;
        top: 140px;
        .legent-box {
          width: 129px;
          height: 66px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .text {
            cursor: pointer;
          }
          .one {
            width: 26px;
            height: 3px;
            background: linear-gradient(258deg, #ffa59e 0%, #e45247 100%);
            border-radius: 87px;
            margin-right: 20px;
          }
          .two {
            width: 26px;
            height: 3px;
            background: linear-gradient(271deg, #f7d9b7 0%, #eabf96 100%);
            border-radius: 87px;
            margin-right: 20px;
          }
          .three {
            width: 26px;
            height: 3px;
            background: linear-gradient(0deg, #a4c3f5 0%, #e0ecff 100%);
            border-radius: 87px;
            margin-right: 20px;
          }
          .t-1 {
            font-weight: 400;
            font-size: 12px;
          }
          .m-1 {
            font-size: 12px;
            background-image: linear-gradient(232deg, #ffa59e 0%, #e45247 100%);
            background-clip: text;
            color: transparent;
            margin: 10px 0;
          }
          .m-2 {
            font-size: 12px;
            background-image: linear-gradient(273deg, #f7d9b7 0%, #eabf96 100%);
            background-clip: text;
            color: transparent;
            margin: 10px 0;
          }
          .m-3 {
            font-size: 12px;
            background-image: linear-gradient(0deg, #a4c3f5 0%, #e0ecff 100%);
            background-clip: text;
            color: transparent;
            margin: 10px 0;
          }
          .b {
            font-size: 12px;
            font-weight: SemiBold;
          }
        }
      }
      .mychart-r {
        width: 450px;
        height: 300px;
        position: absolute;
        right: 0;

        .linechart-t {
          display: flex;
          align-items: center;
          margin: 60px 0 30px 0;
          div {
            font-size: 26px;
            font-weight: 700;
            color: #11263c;
            margin-right: 13px;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #bdc0cb;
          }
        }

        .linechart-b {
          margin: 20px 20px 0 0;
          display: flex;
          .line-box {
            display: flex;
            align-items: center;
            margin-right: 40px;
            .line-l-1 {
              width: 26px;
              height: 3px;
              background: linear-gradient(258deg, #ffa59e 0%, #e45247 100%);
              border-radius: 87px;
              margin-right: 10px;
            }
            .line-l-2 {
              width: 26px;
              height: 3px;
              background: linear-gradient(273deg, #f7d9b7 0%, #eabf96 100%);
              border-radius: 87px;
              margin-right: 10px;
            }
            .line-l-3 {
              width: 26px;
              height: 3px;
              background: linear-gradient(0deg, #a4c3f5 0%, #e0ecff 100%);
              border-radius: 87px;
              margin-right: 10px;
            }
            .line-r {
              width: 26px;
              height: 3px;
              background: #f3f4f6;
              border-radius: 87px;
            }
            .linetext {
              p {
                font-size: 12px;
                font-weight: 400;
                color: #bdc0cb;
              }
              span {
                font-size: 12px;
                color: #11263c;
                font-weight: SemiBold;
              }
            }
          }
        }
      }
    }
    .mydeal {
      width: 852px;
      height: 225px;
      border-bottom: #edf1f7 1px solid;
      .mydeal-t {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 44px 0;
        .mydeal-t-l {
          font-size: 18px;
          font-weight: 700;
        }
        .mydeal-t-r {
          font-size: 16px;
          font-weight: 700;
          color: #bdc0cb;
        }
      }
      .content {
        height: 85px;
        display: flex;
        justify-content: space-between;
        .box {
          height: 85px;
          width: 186px;
          border: 1px solid #edf1f7;
          border-radius: 12px;
          display: flex;
          overflow: hidden;
          justify-content: space-around;
          align-items: center;
          img {
            width: 45px;
            height: 45px;
            border-radius: 10px;
          }
          .box-text {
            font-size: 18px;
            font-weight: 700;
          }
          .ico {
            border-radius: 19px;
            color: #bdc0cb;
          }
          .checked-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            background: #fff5ea;
            color: #ce9b63;
            text-align: center;
            line-height: 25px;
          }
        }
        .checked-box {
          border: 1px solid #fff;
          border-radius: 12px;
          box-shadow: 0px 5px 10px 0px #edf1f7;
        }
      }
    }
    .myhave {
      height: 288px;
      width: 850px;
      border-bottom: 1px #edf1f7 solid;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin: 41px 0 40px 0;
      }
      .myhave-content {
        height: 148px;
        display: flex;
        justify-content: space-between;
        .box {
          width: 268px;
          height: 148px;
          border: 1px solid #ebebeb;
          border-radius: 13px;
          .box-t {
            display: flex;
            margin: 28px 14px 10px 14px;
            justify-content: space-between;
            .text {
              font-size: 20px;
              font-weight: 400;
            }
            .box-r {
              .num {
                font-size: 16px;
                font-weight: 400;
              }
            }
            .rise {
              color: #fa2832;
            }
            .decline {
              color: #01b834;
            }
          }
          .box-m {
            font-size: 12px;
            font-weight: 400;
            color: #bdc0cb;
            margin: 0 0 19px 14px;
          }
          .box-b {
            font-size: 20px;
            font-weight: 400;
            margin-left: 14px;
          }
        }
        .haveclick {
          border: 1px solid #fff;
          border-radius: 13px;
          box-shadow: 0px 4px 12px 0px #edf1f7;
        }
      }
    }
    .myincome {
      height: 490px;
      width: 850px;
      border-bottom: 1px #edf1f7 solid;
      margin-bottom: 68px;
      .myincome-title {
        font-size: 18px;
        font-weight: 700;
        margin: 41px 0 40px 0;
      }
      .chartbox {
        display: flex;
        justify-content: left;
        .myincom-l {
          width: 850px;
          height: 350px;
          border: 1px solid #ebebeb;
          border-radius: 10px;
          .myincome-chart {
            width: 850px;
            height: 260px;
          }
          .bbtn {
            width: 690px;
            height: 38px;
            background: #eff3f9;
            margin: 0 0 0 80px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .bbtn-box {
              width: 156px;
              height: 26px;
              text-align: center;
              line-height: 26px;
              font-size: 12px;
              color: #8691a8;
              font-weight: 400;
            }
            .bg- {
              background: #fff;
            }
          }
        }
      }
      .null_tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom:10px;
        img {
          width: 200px;
          height: 200px;
          margin-bottom: 10px;
        }
        .null_txt {
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ce9b63;
        }
      }
    }
  }
}
</style>
